import Swup from 'swup'
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import renderPage from './renderPage'
import EventBus from '../../core/EventBus'
import ScrollService from '../Scroll'
import { CMS, IE } from '../../core/config'
import { queryAll } from '../../utils/dom'
import { loadComponents, removeComponents } from '../../core/componentUtils'

let skipPopState = false
EventBus.on('swup:custom:block', () => (skipPopState = true))

const scrollStack = []

const pushScrollPosition = () => {
    const location = window.location.href
    const offset = ScrollService.getScroll().y

    scrollStack.push({
        location,
        offset,
    })

    if (scrollStack > 10) {
        scrollStack.shift()
    }
}

const popScrollPosition = () => scrollStack.pop()

let currentScroll = null

if (!CMS && !(IE && IE <= 11)) {
    const linkSelector =
        'a' +
        [
            '[href^="#"]',
            '[href^="http"]',
            '[href^="/files"]',
            '[data-no-swup]',
            '[target="_blank"]',
            '.ajax',
            '[href^="mailto"]',
            '[href^="tel"]',
        ]
            .map((selector) => `:not(${selector})`)
            .join('')

    const swup = new Swup({
        linkSelector,
        cache: false,
        preload: false,
        animationSelector: '.Section',
        plugins: [new SwupBodyClassPlugin()],
        containers: ['#page-content'],
        skipPopStateHandling: (event) => {
            if (skipPopState) {
                skipPopState = false
                return true
            }

            if (event?.state?.source === 'swup') {
                return false
            }

            return true
        },
    })

    swup._handlers.beforeAnimationInStart = []

    swup.renderPage = renderPage

    swup.on('beforeAnimationInStart', () =>
        Promise.all(queryAll('[data-swup]').map((element) => loadComponents(element))),
    )

    swup.on('clickLink', (event) => {
        pushScrollPosition()
        EventBus.emit('swup:clicklink', { event })
    })

    swup.on('willReplaceContent', (event) => {
        const elements = queryAll('[data-swup]')
        queryAll('[data-swup]').forEach((element) => removeComponents(element))
        EventBus.emit('swup:beforecontentchange')
    })

    swup.on('contentReplaced', async (event) => {
        Nette.reinit()
        const id = window.location.hash.replace('#', '')
        const anchor = document.getElementById(id)

        if (currentScroll) {
            setTimeout(() => {
                ScrollService.setPosition(0, currentScroll.offset)
                currentScroll = null
            }, 0)
        } else if (anchor) {
            setTimeout(() => {
                let offset = anchor.matches('.Section:not(.Section--appended):not(.Section--bare)')
                    ? 0
                    : 80
                offset = anchor.getBoundingClientRect().top + ScrollService.getScroll().y - offset
                ScrollService.setPosition(0, offset)
            }, 0)
        } else {
            ScrollService.setPosition(0, 0)
        }

        if ($.nette) {
            $.nette.load()
        }

        EventBus.emit('swup:aftercontentchange')

        setTimeout(() => ScrollService.resize(), 0)
    })

    swup.on('transitionEnd', (event) => {
        EventBus.emit('swup:transitionend')
    })

    swup.on('popState', (event) => {
        const settings = popScrollPosition()
        if (settings?.location == window.location.href) {
            currentScroll = settings
        }
    })
}
